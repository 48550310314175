.pcoded-navbar:not(.theme-horizontal) .navbar-content {
  height: calc(100% - 70px);
}

.card {
  .card-header + .collapse{
    >.card-body{
      padding-top: 0;
    }
  }
}

.pcoded-navbar{
  .pcoded-inner-navbar {
    li {
      > a.active {
        color: #e5efff;
        /*font-weight: 600;*/
      }


      .pcoded-submenu > li {
        &.active,
        &:focus,
        &:hover {
          > a ,
          > a.active {
            &:before {
              background: #e5efff;
            }
          }
        }
        > a.active {
          &:before {
            background: #e5efff;
          }
        }
      }
    }

    > li {
      &.active,
      &.pcoded-trigger {
        > a,
        > a.active {
          color: #e5efff;
        }
      }

    }

    > .pcoded-menu-caption {
      &.active,
      &.pcoded-trigger {
        &:after {
          display: none;
        }
      }
    }
  }
  &.menu-light{
    .pcoded-inner-navbar {
      li {
        > a.active{
          color: $primary-color;
          background: transparent;
          &:before{
            background: $primary-color;
          }
        }

        .pcoded-submenu > li {

          > a.active {
            &:before {
              background: $primary-color;
            }
          }
        }

      }
    }
  }
}

.search-bar {
  display: block;
}

table.dataTable.table-condensed .sorting:after, table.dataTable.table-condensed .sorting_asc:after, table.dataTable.table-condensed .sorting_desc:after{
  top: 16px;
}
table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after{
  font-family: feather !important;
  content: "\e82a";
}
table.dataTable thead .sorting_asc:after{
  content: "\e82d";
}
table.dataTable thead .sorting_desc:after{
  content: "\e82a";
}

.css-1bm1z97-ToastContainer {
  top: 70px !important;
  .alert-inverse .close {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before{
  color: transparent;
  text-shadow: none;
 }

 // custom css
 form label.required:after {
  color: red !important;
  content: " *" !important;
}

// notification UI


.Toastify__toast {
	background-color: $white !important;
	padding         : 20px  !important;
	border-radius   : 3px !important;
	border-top      : 1px solid $gray-300 !important;
	border-bottom   : 1px solid $gray-300 !important;
	border-right    : 1px solid $gray-300 !important;
  font-family     : inherit;
  z-index         : 99999 !important;
}
.Toastify__toast p {
	color: #23282c !important
}
.Toastify__toast h6 {
	color      : $black !important;
	font-weight: 500 !important;
}

// Toastiy: "SUCCESS" Alert
.Toastify__toast--success .fa-check {
	color: $success !important;
}
.Toastify__toast--success .fa-check-circle {
	color: $success !important;
}
.Toastify__toast--success {
	border-left: 3px solid $success !important;
}
.Toastify__close-button--success {
	color: $gray-500 !important;
}

// Toastiy: "INFO" Alert

.Toastify__toast--info .fa-info {
	color: $primary !important;
}
.Toastify__toast--info {
	background-color: $white !important;
	border-left     : 3px solid $primary !important;
}
.Toastify__close-button--info {
	color: $gray-500 !important;
}

// Toastiy: "WARNING" Alert
.Toastify__toast--warning .fa-exclamation {
	color: $warning !important;
}
.Toastify__toast--warning {
	background-color: $white !important;
	border-left     : 3px solid $warning !important;
}
.Toastify__close-button--warning {
	color: $gray-500 !important;
}

// Toastiy: "ERROR" Alert
.Toastify__toast--error .fa-close {
	color: $danger !important;
}
.Toastify__toast--error {
	background-color: $white !important;
	border-left     : 3px solid $danger !important;
}
.Toastify__close-button--error {
	color: $gray-500 !important;
}

// Toastiy: "DEFAULT" Alert
.Toastify__toast--default .fa-exclamation {
	color: $dark !important;
}
.Toastify__toast--default {
	background-color: $white !important;
	border-left     : 3px solid $secondary !important;
}
.Toastify__close-button--default {
	color: $gray-500 !important;
}

// react table css
.ReactTable .rt-tbody .ReactTable_actions {
  overflow: visible !important;
}

.ReactTable .btn_actions {
  width: 100% !important;
}

// client form
.react-datepicker-wrapper{
  width: 100% !important;
}

.client-action-btn li a {
  font-size: 13px !important;
}

// amortization css
.amortization-modal {
  max-width: 95%;
}

.active_amortizations {
  background-color:#13326b !important;
  color:#ffffff !important;
  text-shadow: 1px 2px #000000 !important;
  box-shadow: 0px 0px 10px #ff0000 !important;
  -webkit-box-shadow: 0px 0px 5px #ff0000 !important;
  -moz-box-shadow: 0px 0px 5px #ff0000 !important;
}

.highLightTd {
  background-color: #fffcf0;
}
// end amortization

// payment modal
.process-transaction{
  max-width: 65%;
}

.required_docs_inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

@media only screen and (min-device-width : 375px) 
and (max-device-width : 763px) { 
  .clientPhoto{
    float: none !important;
  }
}

//Move error message to left in a few em
.was-validated .form-control:invalid, .form-control.is-invalid{
  background-position: right calc(1em + 0.1875rem) center;
}

// Treeview
.deni-react-treeview-container {
  font-size: 16px !important;
}

// confirm box
.react-confirm-alert-overlay{
  z-index: 9999 !important;
}

// 
.pro-head{
  max-width: 100% !important;
  width: 250px !important;
}

// 
.apexcharts-legend-text{
  padding-right: 10px !important;
}